class Elementor_Product {
    static instance;

    static getInstance() {
        if (!Elementor_Product.instance) {
            Elementor_Product.instance = new Elementor_Product();
        }
        return Elementor_Product.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
        this.tooltip();
    }

    tooltip() {
        // Tooltip
        let $body = $('body');
        $body.on('hover', '.woocommerce:not(.elementor-product-style-masonry) .opal-add-to-cart-button:not(.tooltipstered)', function(){
            $(this)
                .tooltipster({
                    position: 'left',
                    functionBefore: function(instance, helper) {
                        instance.content(instance._$origin.find('.button').text());
                    },
                    theme: 'opal-product-tooltipster',
                    delay: 0,
                    animation: 'grow',
                })
                .tooltipster('show');
        });

        $body.on('hover', '.woocommerce:not(.elementor-product-style-masonry) li .woosq-btn:not(.tooltipstered),.woocommerce:not(.elementor-product-style-masonry) li .wooscp-btn:not(.tooltipstered), .woocommerce:not(.elementor-product-style-masonry) li .woosw-btn:not(.tooltipstered), .woocommerce:not(.elementor-product-style-masonry) li .compare-button .compare:not(.tooltipstered), .woocommerce:not(.elementor-product-style-masonry) li .yith-wcqv-button:not(.tooltipstered), .woocommerce:not(.elementor-product-style-masonry) li .yith-wcwl-add-to-wishlist > div > a:not(.tooltipstered)', function(){
            $(this)
                .tooltipster({
                    position: 'left',
                    functionBefore: function(instance, helper) {
                        instance.content(instance._$origin.text());
                    },
                    theme: 'opal-product-tooltipster',
                    delay: 0,
                    animation: 'grow'
                })
                .tooltipster('show');
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-products.default', ($scope) => {
            let $carousel = $('.woocommerce-carousel', $scope);
            if ($carousel.length > 0) {
                let data = $carousel.data('settings');
                $('ul.products', $carousel).owlCarousel(Elementor_Carousel.setupData(data));
            }
            let $button = $scope.find('a.elementor-button-load-more');
            let $container = $scope.find('.elementor-widget-container .woocommerce ul.products');
            $button.on('click', function (event) {
                event.preventDefault();
                let settings = $button.data('settings');
                $.ajax({
                    url: osfAjax.ajaxurl,
                    data: {
                        action: 'osf_ajax_loadmore_products',
                        data: settings
                    },
                    type: 'POST',
                    dataType: 'JSON',
                    beforeSend: function () {
                        $('body').addClass('loading');
                    },
                    success: function (response) {
                        $container.append($(response.content).find('.products').html());
                        // $button.data("settings", response.settings);
                        if (response.disable) {
                            $button.remove();
                        }
                        $('body').removeClass('loading');
                    }
                });
            });

            let $masonry = $scope.find('.elementor-product-style-masonry ul.products');
            if($masonry) {
                $masonry.imagesLoaded(function () {
                    $masonry.masonry({
                        itemSelector: 'li.product',
                        columnWidth: 'li.product:nth-child(2)',
                        percentPosition: true,
                    });
                })
            }

        });
    }

}

Elementor_Product.getInstance();


